import React from 'react'
import * as Chakra from '@chakra-ui/react'

import IndustriesOne from '../images/industries-two.jpg'
import IndustriesTwo from '../images/industries-two.jpg'
import IndustriesThree from '../images/industries-three.jpg'
import IndustriesFour from '../images/industries-four.jpg'
import IndustriesFive from '../images/industries-five.jpg'
import IndustriesSix from '../images/industries-six.jpg'

import ArrowRightIcon from '../components/icons/arrow-right-icon'
import CustomCursorHover from '../components/custom-cursor-hover'
import { useSlider } from '../hooks/useSlider'
import { Helmet } from 'react-helmet'
import { SlideTransition } from '../components/animations/slide-transition'

const IndustriesPage = () => {
  const data = [
    {
      title: 'Healthcare Technologies',
      description: `As the healthcare sector continues to
      evolve, Techstream Lab aims to invest in
      healthcare technologies that fundamentally
      transform healthcare practices and improve
      patient economics.`,
      img: IndustriesOne,
      letter: 'A',
      hash: 'healthcare-informatics',
    },
    {
      title: `AI &
      Automation`,
      description: `As automation becomes increasingly more prevalent in all facets of daily life, Artificial Intelligence & Machine Learning is poised to transform all industries and their respective operations.`,
      img: IndustriesTwo,
      letter: 'B',
      hash: 'ai-automation',
    },
    {
      title: `Big Data &
      Analytics`,
      description: `Leveraging large amounts of data can uncover unrealized relationships and build opportunities to propel companies into leadership positions within their respective industries.`,
      img: IndustriesThree,
      letter: 'C',
      hash: 'big-data-analytics',
    },
    {
      title: `Financial
      Technology`,
      description: `Techstream Lab focuses on the integration of new and innovative technologies in existing FinTech operations. `,
      img: IndustriesFour,
      letter: 'D',
      hash: 'financial-technology',
    },
    {
      title: `Agriculture &
      Sustainability`,
      description: `The future of agriculture brings exciting opportunities for the integration of innovative solutions to help solve our global sustainability crisis and provide adequate food sources to the world’s population. `,
      img: IndustriesFive,
      letter: 'E',
      hash: 'agriculture-sustainability',
    },
    {
      title: `Blockchain &
      Decentralization`,
      description: `The various applications of blockchain technology and its potential to decentralize traditional and archaic institutions are proving to be limitless.`,
      img: IndustriesSix,
      letter: 'F',
      hash: 'blockchain-decentralization',
    },
  ]

  let {
    currentSlideIndex,
    currentTitle,
    slideForward,
    slideBackward,
  } = useSlider(data)

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <SlideTransition
        number="4"
        title="Industries"
        currentSlideIndex={currentSlideIndex}
        data={data}
      />
      <Chakra.Flex
        h="16vh"
        paddingTop="5vh"
        pos="fixed"
        top={{ base: 'auto', lg: '0' }}
        bottom={{ base: '160px', lg: 'auto' }}
        right={{
          base: '50px',
          lg: 'calc(max(4vw, 40px) + max(120px, 6vw))',
        }}
        fontSize="18px"
        textTransform="uppercase"
        alignItems="center"
        textStyle="basisGrotesque"
        zIndex="500"
      >
        <CustomCursorHover
          d="flex"
          alignItems="center"
          onClick={() => window.history.back()}
          color="#fff"
        >
          <ArrowRightIcon
            style={{
              marginRight: '20px',
              transform: 'rotate(180deg)',
            }}
          />
          Back
        </CustomCursorHover>
      </Chakra.Flex>
      <Chakra.Flex
        pos="fixed"
        zIndex="5"
        bottom="0"
        h={{ base: '120px' }}
        bg="#15151D"
        w={{ base: '100%', lg: 'calc(100% - 7vw)' }}
        px="max(4vw, 40px)"
        alignItems="center"
        justify="space-between"
        color="#fff"
      >
        <Chakra.Flex>
          <CustomCursorHover
            mr="45px"
            py="15px"
            onClick={slideForward}
          >
            <ArrowRightIcon transform="rotate(180)" />
          </CustomCursorHover>
          <CustomCursorHover
            py="15px"
            onClick={slideBackward}
          >
            <ArrowRightIcon />
          </CustomCursorHover>
        </Chakra.Flex>

        <Chakra.Text
          fontSize={{
            '2xl': '32px',
          }}
          textStyle="basisGrotesque"
        >
          05—{data[currentSlideIndex].letter}
        </Chakra.Text>
      </Chakra.Flex>
    </>
  )
}

export default IndustriesPage
